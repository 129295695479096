import { useNavigate } from "react-router-dom";

import { DownloadOutlined, UploadFile } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { useState } from "react";
import CobraProductSerialNumberServices from "services/cobraProductSerialNumber";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

type TCreateProductSerialNumber = {
  serial_number: string;
  product_category: string;
  product_name: string;
  product_serial_name: string;
  warranty_period: number;
  shop: string;
};

const ImportProductSerialNumber = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [products, setProducts] = useState<TCreateProductSerialNumber[]>([]);
  const [error, setError] = useState<{
    message: string;
    data: string[];
  } | null>(null);

  const onSubmit = async () => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการเพิ่มข้อมูล ${products.length} ราการใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CobraProductSerialNumberServices.create(token as string, { products })
          .then((res) => {
            if (res.data.data != null) {
              setError(res.data);
            } else {
              onAlert("success", "สร้างรายการสำเร็จ");
              navigate("/product-serial-number/cobra/list");
            }
          })
          .catch((err) => {
            onAlert("error", "Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  const readUploadFile = (event: any) => {
    event.preventDefault();
    if (event.target.files) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        const dataDefault: TCreateProductSerialNumber[] = json.map((d: any) => {
          return {
            serial_number: d["Serial Number"],
            product_category: d["Category"],
            product_name: d["Model"],
            product_serial_name: d["Product Name"],
            warranty_period: d["Warranty Period"]
              ? parseInt(d["Warranty Period"])
              : 0,
            shop: d["Shop"],
          } as TCreateProductSerialNumber;
        });
        const duplicateSerialNumbers = findDuplicateSerialNumbers(dataDefault);
        if (duplicateSerialNumbers.length > 0) {
          setError({
            message: "Serial numbers are duplicate in file",
            data: duplicateSerialNumbers
          });
        } else {
          setFileName(event.target.files[0].name);
          setProducts(dataDefault);
          setError(null);
        }
        setIsLoading(false);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  const findDuplicateSerialNumbers = (
    products: TCreateProductSerialNumber[]
  ) => {
    let seen = new Set<string>();
    let duplicates = new Set<string>();

    products.forEach((product) => {
      if (seen.has(product.serial_number)) {
        duplicates.add(product.serial_number);
      } else {
        seen.add(product.serial_number);
      }
    });

    return Array.from(duplicates);
  };

  return (
    <>
      <Header
        title="Import new product serial numbers"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/dashboard/cobra`,
          },
          {
            label: "Product Serial Number",
            href: "/product-serial-number/cobra/list",
          },
          {
            label: "New product serial number",
          },
        ]}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <a href="/import_template.xlsx" download>
          <Button
            size="large"
            disabled={isLoading}
            sx={{
              mr: 2,
            }}
          >
            <DownloadOutlined
              sx={{
                fontSize: 20,
                mr: 1,
              }}
            />
            Template
          </Button>
        </a>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          component="label"
          disabled={isLoading}
          sx={{
            mr: 2,
          }}
        >
          <UploadFile
            sx={{
              fontSize: 20,
              mr: 1,
            }}
          />
          <Typography>Import</Typography>
          <input
            type="file"
            name="upload"
            id="upload"
            accept=".csv,.xlsx"
            onChange={readUploadFile}
            hidden
          />
        </Button>
        <p>{fileName}</p>
      </div>

      {error ? (
        <div
          style={{
            border: "1px solid red",
            borderRadius: "12px",
            padding: "8px",
            marginTop: "16px",
            overflowY: "auto",
            maxHeight: "500px",
          }}
        >
          <p>
            <span
              style={{
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Error:
            </span>
            {error.message}
          </p>
          <ul>
            {error.data.map((d) => {
              return <li style={{ whiteSpace: "pre" }}>{d}</li>;
            })}
          </ul>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 12,
        }}
      >
        <Button
          type="button"
          variant="contained"
          size="large"
          color="error"
          onClick={() => navigate(`/product-serial-number/cobra/list`)}
        >
          <Typography>Back</Typography>
        </Button>
        <Button
          type="button"
          variant="contained"
          size="large"
          disabled={isLoading || error !== null || products.length === 0}
          onClick={onSubmit}
        >
          <Typography>
            {isLoading ? "Loading..." : `Create (${products.length})`}
          </Typography>
        </Button>
      </div>
    </>
  );
};

export default withAuth(
  withPermission(ImportProductSerialNumber, "MANAGE_COBRA")
);
